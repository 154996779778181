import { Component, Inject, Prop, Vue } from "vue-property-decorator";
import { CONTAINER_TYPES } from "../../../../infrastructure/ioc/container-types";
import ServiceInterface from "@/vue/service/service-interface";
import DfBrand from "@/vue/domain/brand/df-brand";
import Utils from "@/vue/infrastructure/utils/helpers";
import Store from "@/vue/infrastructure/store/store";
import DfStore from "@/vue/domain/store/df-store";

@Component
export default class DfHeaderMobileComponent extends Vue {
  @Inject(CONTAINER_TYPES.ServiceInterface)
  private service!: ServiceInterface;

  @Prop({ type: Boolean, default: true })
  showBrandLogoImageUrl!: boolean;
  @Prop({ type: Boolean, default: true })
  showBackButton!: boolean;

  get backIconImageUrl(): string {
    return `${Utils.getPublicPath()}/assets/back-icon.svg`;
  }

  get brand(): DfBrand {
    return this.$store.getters.brand;
  }

  get currentStore(): DfStore {
    return this.$store.getters.currentStore;
  }

  get headerPreviewStyle(): string {
    const style: any = {};
    if (this.selexLogoHeader) {
      style.backgroundImage = `url('${this.selexLogoHeader}')`;
    }

    return style;
  }

  get selexHomeUrl(): string {
    return Utils.getPropertyValue(this.brand, Utils.PROPERTY_SELEX_HOME_URL, "STRING");
  }

  get selexLogoHeader(): string {
    return Utils.getPropertyImageUrl(this.brand, Utils.PROPERTY_SELEX_LOGO_HEADER, null);
  }

  private openUrl(url: string) {
    window.open(url, "_self");
  }

  private goBack() {
    Utils.goBack(this);
  }
}
